@import '../../../../scss/theme-bootstrap';

.cart-item {
  @include pie-clearfix;
  display: block;
  text-align: left;
  color: $color-black;
  padding-top: 20px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.35);
  text-transform: none;
  &:hover {
    color: $color-black;
  }
  &:after {
    padding-top: 20px;
  }
  // Layout:
  &__img,
  &__info,
  &__cart-details {
    float: left;
    display: block;
  }
  &__img {
    width: 30%;
    height: auto;
    margin-right: 2%;
    max-width: 75px;
    @include breakpoint($landscape-up) {
      max-width: 100px;
    }
  }
  &__info {
    width: 46%;
    margin-right: 4%;
    @include breakpoint($landscape-up) {
      width: 39%;
    }
  }
  &__cart-details {
    width: 21%;
    float: right;
    text-align: right;
    @include breakpoint($landscape-up) {
      width: 30%;
    }
  }

  // Styles:
  &__subheading {
    padding-bottom: 5px;
  }
  &__title {
    margin: 0 0 3px 0;
  }
  &__qty {
    margin-bottom: 5px;
    @include breakpoint($landscape-up) {
      float: left;
      padding-bottom: 0;
    }
  }
  &__price {
    color: $color-black;
    float: right;
    &-discount {
      color: #{$red};
    }
    &-strike-out {
      text-decoration: line-through;
    }
  }
  &__shade {
    @include pie-clearfix;
    margin: 10px 0 0;
    &__name {
      display: inline-block;
      margin: 0;
      float: left;
    }
  }
}
